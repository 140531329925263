<template>
    <div class="container">
        <form @input.once="isFormEdited = true">
            <div class="row">
                <base-input class="form-group col-md-6" v-model.trim="formData.firstName" type="text"
                            placeholder="First name" :error="errors.first_name"></base-input>
                <base-input class="form-group col-md-6" v-model.trim="formData.lastName" type="text"
                            placeholder="Last name" :error="errors.last_name"></base-input>
            </div>
            <custom-form-component ref="customForm" :customFormClasses="customFormClasses"
                                   :customFieldClasses="customFieldClasses"></custom-form-component>
            <div class="row">
                <base-input class="form-group col-md-4" :value="user && user.email" disabled type="text"></base-input>
                <base-input class="form-group col-md-4" v-model.trim="formData.password" type="password"
                            isPassword="true" placeholder="New password (optional)"
                            :error="errors.password"></base-input>
                <base-input class="form-group col-md-4" v-model.trim="formData.passwordConfirmation"
                            type="password" isPassword="true" placeholder="Password confirmation"
                            :error="errors.password"></base-input>
            </div>
            <button  @click.stop.prevent="updateProfile"
                     class="w-100 btn btn-block btn-dark"
                     :disabled="!isFormEdited"
            >{{ $t('Update') }}</button>
        </form>
    </div>
</template>

<script>

    import { mapState, mapActions } from "vuex";
    import CustomFormComponent from "../CustomFormComponent";
    import BaseInput from "../base-components/BaseInput";

    export default {
        name: "UpdateProfileComponent",
        components: { CustomFormComponent, BaseInput },
        data() {
            return {
                loading: false,
                additionalParamsToErrormessage: null,
                checked: false,
                formData: {
                    firstName: '',
                    lastName: '',
                    password: '',
                    passwordConfirmation: ''
                },
                errors: {
                    first_name: '',
                    last_name: '',
                    password: ''
                },
                customFormClasses: ["row"],
                customFieldClasses: ["col-md-6"],
                isFormEdited: false
            }
        },
        methods: {
            ...mapActions(['DO_UPDATE_PROFILE']),
            updateProfile() {
                this.clearErrors();
                if (!this.validateInputs()) {
                    return;
                }
                this.loading = true;
                let data = {
                    first_name: this.formData.firstName,
                    last_name: this.formData.lastName,
                    password: this.formData.password,
                    password_confirmation: this.formData.passwordConfirmation
                }

                Object.assign(data, this.$refs.customForm.getFieldsData());
                this.DO_UPDATE_PROFILE(data).then(() => {
                    this.$refs.customForm.getCustomFormData();
                    this.loading = false;
                    this.clearErrors();
                    this.$router.push('/profile');
                }).catch((data) => {
                    this.loading = false;
                    if (data && data.errors) {
                        for (const field in data.errors) {
                            for (const error of data.errors[field]) {
                                this.errors[field] = error.message;
                            }
                        }
                    }
                })
            },
            validateCustomInputs() {
                return this.$refs.customForm.validate();
            },
            validateInputs() {
                let customValidate = this.validateCustomInputs();
                this.errors.first_name = !this.formData.firstName && 'Enter your first name';
                this.errors.last_name = !this.formData.lastName && 'Enter your last name';
                if (this.formData.password || this.formData.passwordConfirmation) {
                    if (this.formData.password !== this.formData.passwordConfirmation) {
                        this.errors.password = 'The password and password confirmation do not match'
                    }
                    else {
                        this.errors.password = (this.formData.password.length < 8) && 'The password is too short';
                    }
                }
                for (const error in this.errors) {
                    if (this.errors[error]) {
                        return false;
                    }
                }
                return customValidate;
            },
            clearErrors() {
                for (const error in this.errors) {
                    this.errors[error] = '';
                }
            },
        },
        computed: {
            ...mapState({
                user: state => state.auth.user
            }),
        },
        mounted() {
            this.formData.firstName = this.user.first_name;
            this.formData.lastName = this.user.last_name;
        }
    }
</script>

<style lang="scss" scoped>

</style>