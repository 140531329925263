<template>
  <order-component :status="$options.STATUS" :display-type="$options.DISPLAY_TYPE" class="overflow-auto">
    <template v-slot:empty>
      <div class="mt-5 mb-5 text-center">
        <h3>{{$t('empty history')}}</h3>
      </div>
    </template>
  </order-component>
</template>

<script>
  import OrderComponent from "./OrdersComponent";
  import {ORDER_DISPLAY_TYPE, ORDER_STATUS} from "../../const";
  export default {
    name: "HistoryOrdersList",
    components: {OrderComponent},
    STATUS: [ORDER_STATUS.paid, ORDER_STATUS.refunded, ORDER_STATUS.failed, ORDER_STATUS.expired],
    DISPLAY_TYPE: ORDER_DISPLAY_TYPE.list
  }
</script>