<template>
    <div v-if="customForm && customForm.length" :class="customFormClasses">
        <custom-field-component v-for="form in customForm"
                                :key="form.name + form.value"
                                :data="form" :customFieldClasses="customFieldClasses"
        ></custom-field-component>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import CustomFieldComponent from "./CustomFieldComponent";

    export default {
        components: {CustomFieldComponent},
        name: "CustomFormComponent",
        data() {
            return {
                alias: 'user',
            }
        },
        props: {
            customFormClasses: Array,
            customFieldClasses: Array
        },
        methods: {
            ...mapActions(['GET_CUSTOM_FORM_DATA']),
            getCustomFormData() {
                this.GET_CUSTOM_FORM_DATA(this.alias)
            },
            validate() {
                if (!this.$children.length) return true;
                let isAllValid = [];
                this.$children.forEach(item => {
                    if ('isValid' in item && typeof item.isValid === 'function') {
                        isAllValid.push(item.isValid())
                    }
                })
                return isAllValid.every(i => i)
            },
            getFieldsData() {
                let data = {}
                this.$children.forEach(child => {
                    if ("formData" in child && child.formData.name) {
                        data[child.formData.name] = child.value
                    }
                })
                return data
            }
        },
        computed: {
            customForm() {
                return this.$store.getters.GET_CUSTOM_FORM_DATA_FOR_ALIAS(this.alias)
            }
        },
        created() {
            if (!this.customForm.length){
                this.getCustomFormData()
            }
        }
    }
</script>

<style scoped>

</style>