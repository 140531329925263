<template>
  <router-link :to="{path: `/watch/${event.id}`}" class="clips-link">    
  <div class="event-container">
    <div class="blocks">
      <div class="block block--left" :style="{ backgroundColor: teams.homeTeam.color ? teams.homeTeam.color : '#FFFFFF' }">
        <div class="name-event">
          <span v-if="category">{{ category }}</span>
        </div>
        <image-component :path="teams.homeTeam.img" :alt="teams.homeTeam.name" />
      </div>
      <div class="block block--right" :style="{ backgroundColor: teams.awayTeam.color ? teams.awayTeam.color : '#041E42' }">
        <image-component :path="teams.awayTeam.img" :alt="teams.awayTeam.name" class="ml-auto" />
        <div class="date-time">
          <div>
            <span>
              {{ time.month }}
              {{ time.day }}
            </span>
            <span>
              {{ time.hh }} {{ time.zone }}
            </span>
          </div>
        </div>

      </div>
    </div>
    <div class="details-game">
      <span>{{ teams.homeTeam.name }} vs {{ teams.awayTeam.name }}</span>
    </div>
  </div>
</router-link>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import ImageComponent from '@/components/base-components/ImageComponent';
import { getAwayHomeTeams } from '@/utils/helpers';

export default {
  name: 'ClipsSourceList',
  components: { ImageComponent },
  props: {
    event: {
      type: Object,
      time_zone: String,
      event_utc_ts: Number,
    },
  },
  data() {
    return {
      timeZone: this.event?.time_zone || '',
      message: this.event.caption,
    };
  },
  methods: {
    ...mapActions(['GET_EVENT_CATEGORIES']),
  },
  mounted() {
    console.log("mounted", this.event)
    console.log("teams", this.teams)

    this.GET_EVENT_CATEGORIES();
  },
  computed: {
    ...mapState({
      categories: (state) => state.event.eventCategoriesById,
    }),
    category() {
      return this.categories?.[this.event.event_category_id]?.name;
    },
    homeTeamColor() {

    },

    teams() {
      return getAwayHomeTeams(this.event);
    },
    time() {
      const { month, day, hh, zone } = this.getFormattedDate({
        ts: this.event.event_utc_ts,
        timeZone: this.timeZone,
        formatsOptions: {
          month: { month: 'short' },
          day: { day: '2-digit' },
          hh: { hour: '2-digit', hourCycle: 'h12', minute: '2-digit' },
        },
      });
      return {
        month,
        day,
        hh,
        zone,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.details-game {
  text-align: center;
  margin: 5px;
  color: var(--dark, #1C1C1C);
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.8px;
}

.event-box {
  height: 145px;
  width: 100%;

  display: flex;

  flex-direction: column;

}

.team-container {
  display: flex;
}

.flex-row {
  display: flex;
  width: 100%;
}

.image-component {
  flex: 1;
  width: 100%;
  height: 100%;
}

.text-team {
  font-weight: 600;
  margin-bottom: 4px;
}

.date-time {
  margin-top: auto;
  background-color: #E41D34;
  display: flex;
  width: 100%;
  height: 18px;
  color: #FFF;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
}

.ml-auto {
  margin-left: auto;
}

.date-time :first-child {
  margin: auto;
}

.name-event {
  background-color: #E41D34;
  color: #FFF;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  width: 100%;
  height: 25px;
}

.name-event :first-child {
  margin-right: auto;
  margin-top: 4px;
  margin-left: 10px;
}

.clips-link {
  cursor: pointer;
}



img {
  width: 68px;
  height: 68px;
}


.blocks {
  display: flex;
}

.block {
  min-height: 145px;
  width: 50%;
  width: calc(50% + 2rem);
}

.block--left {
  clip-path: polygon(0 0, 100% 0, calc(100% - 3rem) 100%, 0% 100%);
}

.block--left img {
  margin-top: 16px;
  margin-left: 20px;
}

.block--right {
  display: flex;
  flex-direction: column;
  margin-left: -3.1rem;
  clip-path: polygon(3rem 0, 100% 0, 100% 100%, 0% 100%);
}

.block--right img {
  margin-top: 37px;
  margin-right: 20px;
}
</style>
  