<template>
  <div class="order-list">
    <div class="link-wrapper">
      <app-link :to="{name: 'active orders'}" class="link"> {{$t('active orders')}}</app-link>
      <app-link :to="{name: 'transactions'}" class="link"> {{$t('history')}} </app-link>
      <app-link :to="{name: 'clips'}" class="link"> {{$t('clips')}} </app-link>
    </div>
    <router-view/>
  </div>
</template>

<script>
import AppLink from '../base-components/AppLink';
export default {
  name: 'OrdersList',
  components: { AppLink },
};
</script>

<style lang="scss" scoped>
.link-wrapper {
  display: flex;
  gap: 1rem;
  padding: 1.5rem 0 0.5rem;
  flex-wrap: wrap;
  width: 100%;
}
.link {
  color: var(--settings-body-text-color);
  padding: 0.7rem 1rem;
  border: 1px solid var(--settings-body-text-color);
  border-radius: 0.2rem;
  opacity: 0.8;
  transition: all 0.15s linear;
  &:hover {
    text-decoration: none;
    opacity: 1;
    color: inherit;
  }
  &.router-link-exact-active {
    color: var(--order-active-tab-text);
    border-color: var(--order-active-tab-text);
    opacity: 1;
  }
}
</style>