<template>
  <div class="main-container mb-5">
    <div class="profile main-content">
      <div class="text-left profile_user">
        <div class="account_head mb-4 mt-4">
          <h3>{{ $t('My Account') }}</h3>
          <router-link :to="toggleRoutePath" class="btn btn-dark"
            ><i class="fas fa-pen"></i
          ></router-link>
        </div>
        <div class="form-group">
          <label>{{ $t('Name') }}: </label>
          <div class="">
            {{ user && user.first_name }} {{ user && user.last_name }}
          </div>
        </div>
        <div class="form-group">
          <label>{{ $t('E-Mail Address') }}: </label>
          <div>{{ user && user.email }}</div>
        </div>
        <div
          v-for="(value, label) in customFields"
          :key="label + value"
          class="form-group"
        >
          <label>{{ $t(label) }}: </label>
          <div>{{ value }}</div>
        </div>
        <button @click="logout" class="w-100 btn btn-block btn-dark">
          {{ $t('Logout') }}
        </button>
      </div>
      <div class="text-left routing_data">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ProfileScreen',
  data() {
    return {
      alias: 'user',
    };
  },
  methods: {
    ...mapActions(['DO_LOGOUT', 'GET_CUSTOM_FORM_DATA']),
    logout() {
      this.DO_LOGOUT().then(() => {
        this.$store.commit('RESET_STATE');
        this.$router.go(this.$router.currentRoute);
      });
    },
    getCustomFormData() {
      this.GET_CUSTOM_FORM_DATA(this.alias);
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    isEditRoute() {
      return this.$route.name == 'edit';
    },
    customFields() {
      let fields = this.$store.getters.GET_CUSTOM_FORM_DATA_FOR_ALIAS(
        this.alias
      );
      let data = {};
      Array.isArray(fields) &&
        fields.forEach((field) => {
          if (field.options && Object.keys(field.options).length) {
            if (!field.options[field.value]) return;
            data[field.label] = field.options[field.value];
          } else {
            data[field.label] = field.value;
          }
        });
      return data;
    },
    toggleRoutePath() {
      return this.isEditRoute ? '/profile' : '/profile/edit';
    },
  },
  created() {
    if (!this.$store.getters.isLogged) {
      this.$router.push({ name: 'login' });
    } else {
      this.getCustomFormData();
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "src/assets/css/mixins";

.account_head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5em;
  align-items: flex-start;
}

.form-group {
  word-wrap: break-word;
}

.profile {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  @include media-max(md) {
    flex-direction: column;
  }
}
.profile_user {
  min-width: 17rem;
  max-width: 25rem;
  flex-grow: 1;
  @include media-max(md) {
    max-width: 100%;
    flex-grow: 0;
  }
}
.routing_data {
  flex-grow: 3;
  max-width: 100%;
}
.form-group {
  word-wrap: break-word;
}

  .profile{
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    @include media-max(md){
      flex-direction: column;
    }
  }
  .profile_user{
    min-width: 17rem;
    max-width: 25rem;
    flex-grow: 1;
    @include media-max(md){
      max-width: 100%;
      flex-grow: 0;
    }
  }
  .routing_data{
    flex-grow: 3;
    max-width: 72%;
  }
</style>
