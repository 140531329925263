<template>
    <div class="has-text-left" :data-id="event.id">
        <div class="columns mt-3 is-multiline is-mobile">
            <figure class="image is-48x48 team-figure" v-if="event.participants[0] && event.participants[0].images">
                <image-component class="is-rounded team-img" :path="event.participants[0].images" alt="banner" />
            </figure>
            <figure class="image is-48x48 team-figure" v-if="event.participants[1] && event.participants[1].images">
                <image-component class="is-rounded team-img" :path="event.participants[1].images" alt="banner"/>
            </figure>
            <div class="column" v-if="event.participants[0] && event.participants[1]">
                <p class="has-text-weight-semibold is-4"> {{this.trimParticipant(event.participants[0].name)}} VS
                    {{this.trimParticipant(event.participants[1].name)}}</p>
                <p class="subtitle is-size-7 has-text-grey"><span
                        v-if="event.caption"> {{event.caption}}  &#183; </span>
                    {{this.getMonthAndDay(event.event_utc_ts)}} &#183; {{this.getHoursAndMinutes(event.event_utc_ts)}}
                    <span v-if="event.participants[0] && event.participants[0].properties.Sports"> &#183; {{event.participants[0].properties.Sports[0]}} </span>
                </p>
            </div>
            <div class="column has-text-centered" v-else>
                <p class="has-text-weight-semibold is-4"> {{event.name}}  {{event.id}}</p>
                <p class="subtitle is-size-7 has-text-grey"><span
                        v-if="event.caption"> {{event.caption}}  &#183; </span>
                    {{this.getMonthAndDay(event.event_utc_ts)}} &#183; {{this.getHoursAndMinutes(event.event_utc_ts)}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import ImageComponent from "../base-components/ImageComponent";
    export default {
        name: "GameComponent",
      components: {ImageComponent},
      props: {
            event: Object
        },
        methods: {
            choseMediaUrl(media) {
                for(let i in media){
                    let v = media[i];
                    if (v && 1 == v.media_type) {
                        return v.media_url;
                    }
                }
                return '';
            },
        },
    }
</script>

<style lang="scss" scoped>
    .team-img {
        border: 1px solid gray;
        padding: 3px;
    }

    .team-figure {
        background-color: white;
        margin-left: 1rem;
        margin-top: 1rem;

    }

    .team-figure:nth-child(2) {
        margin-left: -5px;
    }
</style>
