<template>
  <div class="mb-4">
    <div v-if="loading" class="d-flex justify-content-center">
      <loader/>
    </div>
    <div v-else-if="sourceClips.length" class="clips-container">
      <div v-for="data in sourceClips" :key="data.id" class="clip-item">
        <ClipsSourceList :event="data"/>
      </div>
    </div>
    <div v-else class="my-5 text-center">
      <h3>You do not have clipped events yet</h3>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import OrdersComponent from '@/components/profile/OrdersComponent';
  import GameComponent from '@/components/main/GameComponent';
  import ClipsSourceList from '@/components/clip/ClipsSourceList';
  import Loader from "../Loader";

  export default {
    name: 'VideoClippingProfileComponent',
    components: {Loader, OrdersComponent, GameComponent, ClipsSourceList},
    data() {
      return {
        requestUpcomingEventsParams: {
          start_date: Math.floor(Date.now() / 1000),
          limit: 3,
        },
        loading: false,
        data: [],
      };
    },
    methods: {
      ...mapActions({
        getAllClips: 'GET_ALL_USER_SOURCE_CLIPS',
      })
    },
    computed: {
      ...mapState({
        participants: (state) => state.participant.participantsList,
        user: (state) => state.auth.user,
      }),
      ...mapGetters({
        sourceClips: 'getSourceClips',
      }),
    },
    mounted() {
      this.loading = true;
      this.getAllClips().then(() => {
        this.loading = false;
      })
    },
  };
</script>

<style lang="scss" scoped>
  .clips-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    

  }

  .clip-item {
    font-size: 0.8rem;
    border-radius: 0.5rem;
    overflow: hidden;
    width: 256px;
    height: 186px;
    min-width: 256px;
    min-height: 186px;
    box-shadow: var(--card-shadow);
    line-height: 1rem;
  }
</style>