<template>
    <div class="form form-group" :class="customFieldClasses">
        <!--        <label :for="formData.name">{{formData.label}}</label>-->
        <select v-if="formData.type === 'select'"
                :name="formData.name"
                :id="formData.name"
                v-model="value"
                @change="changeHandler"
                :class="['form-control', errors.length && 'is-invalid']">
            <option :value="''">Choose {{formData.label}}</option>
            <option v-for="(val, key) in formData.options" :key="key" v-bind:value="key">
                {{val}}
            </option>
        </select>
        <input v-else :type="formData.type"
               :name="formData.name"
               :id="formData.name"
               v-model.trim="value"
               :placeholder="formData.label"
               @input="changeHandler"
               :class="['form-control', errors.length && 'is-invalid']"
        >
        <div v-if="errors.length">
            <div class="field-icon error" v-for="err in errors" :key="err">
                <small class="text-danger">
                    {{err}}
                </small>
            </div>
        </div>
    </div>
</template>

<script>

import {ERROR} from "../const";
import {validateEmail} from "@/utils/helpers";

    export default {
        name: "CustomFieldComponent",
        data() {
            return {
                value: this.data.value || this.data.default_value,
                formData: this.data,
                errors: []
            }
        },
        props: {
            data: {
                type: Object,
                required: true
            },
            customFieldClasses: Array
        },
        methods: {
            changeHandler() {
                this.errors.splice(0);
            },
            newFunction() {
                /**
                 *  Written by Lucas - Icaromediagroup
                 * This parses data from changeHandler to backend api
                 */
            },  
            isValid() {
                this.errors.splice(0);
                if (this.data.required) {
                    if (!this.value) {
                        this.errors.push('Field is required');
                        return false;
                    }
                    if (this.data.type === 'email' && !validateEmail(this.value)) {
                        this.errors.push(ERROR.WRONG_EMAIL);
                        return false;
                    }
                    if (this.data.min_length && this.value.length <= this.data.min_length) this.errors.push('Field length must be greater than ' + this.data.min_length);
                    if (this.data.max_length && this.value.length >= this.data.max_length) this.errors.push('Field length must be less than' + this.data.max_length);
                }
                return !this.errors.length
            }
        }
    }
</script>

<style scoped>

</style>